import * as React from 'react';

import WeddingDetails from './../components/weddingDetails';
import { StyledButton } from './../components/Button';
import { Link } from 'gatsby';
import styled from 'styled-components';

const IndexPage = () => {
  return (
    <>
      <h1 className='heading'>We're finally getting married!</h1>
      <WeddingDetails />
      <StyledLinkButton as={Link} to='/details'>
        Wedding Details
      </StyledLinkButton>
      <section style={{ marginTop: '40px' }}>
        <h2>Our Story</h2>
        <p>
          A love story that started in 2011 by trading quesadillas for iced venti mochas when Max
          worked at Qdoba and Michael at the Starbucks across the street in downtown Pittsburgh. It
          took awhile for everything to fall into place and to make it official, but the summer of
          2012 found us running around, causing trouble together, and staring at the stars, falling
          deep in love{' '}
          <span role='img' aria-label='sparkling heart emoji'>
            💖
          </span>
          .{' '}
        </p>
        <p>
          Fast forward to 2017: still madly in love, we have bought a house together and adopted two
          of the cutest, most well behaved 15 pound dogs you can find (okay, maybe not well behaved{' '}
          <span role='img' aria-label='raised eyebrow emoji'>
            🤨
          </span>
          ). On a trip to Nashville to visit our college roommate (hey Lizzy!) and go to Ariana
          Grande’s Dangerous Woman tour, Max finally popped the question{' '}
          <span role='img' aria-label='engagement ring emoji'>
            💍
          </span>
          . Michael was also planning to ask on this trip, but decided not to because it fell over
          Valentine’s Day and he hated the idea of getting engaged on a holiday. Oops!{' '}
        </p>
        <p>
          Next thing we know, it's 2020 and we’re stuck in the house bored out of our minds – what
          better to do than plan the wedding we have been perpetually procrastinating! Wedding
          planning during the unknowns of a pandemic has presented its challenges, but shoutout to
          those stimmy checks!{' '}
          <span role='img' aria-label='moneybag emojis'>
            💰💰
          </span>{' '}
          We're so excited to finally get married and we can't wait to celebrate with our closest
          friends and family!
        </p>
        <StyledLinkButton as={Link} to='/rsvp'>
          RSVP
        </StyledLinkButton>
      </section>
    </>
  );
};

const StyledLinkButton = styled(StyledButton)`
  text-decoration: none;
  display: block;
  margin: 32px 0;
`;

export default IndexPage;
