import * as React from "react"
import styled from 'styled-components'
import Loops from './../images/svg/loopDivider.svg'

const WeddingDetails = () => (
<DetailsBox>
    <h2>Friday, September 17, 2021</h2>
    <p>4:30 in the afternoon</p>
    <LoopDivider>
     
        <LoopsStyled/>
        
    </LoopDivider>
    <h2>The Atrium</h2>
    <p>1031 New Castle Road <br/>
Prospect, PA 16052</p>
</DetailsBox>
)

const DetailsBox = styled.div`
    background-color: rgba(255,255,255,.5);
    border: 1px solid var(--light-green);
    padding: 24px 16px;
    text-align: center;
`

const LoopDivider = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    margin: 24px auto;

    .dotted-line {
        width: 25%;
        border-bottom: 1px dashed var(--blue);
    }
`

const LoopsStyled = styled(Loops)`
    fill: var(--blue);

`
    

export default WeddingDetails